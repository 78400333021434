
.slider_flex .slick-track {
    display: flex !important;
}
.slider_flex.gap_24 .slick-track {
    @apply gap-3 md:gap-4 lg:gap-6;
}

.slider_flex.gap_8{
    @apply -mx-2.5;
}
.slider_flex.gap_8 .slick-track {
    @apply gap-2.5;
}
.slider_flex.gap_14{
    margin-left: -7px;
    margin-right: -7px;
}
.slider_flex.gap_14 .slick-track {
    @apply gap-3.5 ;
}

.slider_flex.gap_4{
    @apply -mx-1.5;
}
.slider_flex.gap_4 .slick-track {
    @apply gap-1.5;
}

.slider_flex .slick-track .slick-slide {
    height: inherit !important;
}
.slider_flex .slick-list,
.slider_flex .slick-list .slick-track,
.slider_flex .slick-track .slick-slide > div,
.slider_flex .slick-track .slick-slide .slider_item,
.slider_flex .slick-track .slick-slide .slider_item > div {
    height: 100% !important;
}