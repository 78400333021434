@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import 'assets/fonts/stylesheet.css';
@import 'assets/css/custom.css';
@import 'assets/css/slider.css';

* {
    @apply outline-0;
}

@media (min-width: 1400px) {
    .container {
        max-width: 1320px !important;
    }
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
    display: unset;
}

body,
html {
    @apply font-primary font-normal bg-white overflow-x-hidden;
    line-height: 1.3;
    letter-spacing: 0.03em;
}

::-webkit-scrollbar {
    @apply w-1.5 h-1.5;
}

::-webkit-scrollbar-track {
    @apply w-1.5 bg-white;
}

::-webkit-scrollbar-thumb {
    @apply bg-success-900 rounded-full;
}


.theme-scrollbar::-webkit-scrollbar {
    @apply w-1.5 h-1.5;
}

.theme-scrollbar::-webkit-scrollbar-track {
    @apply w-1.5 bg-white;
}

.theme-scrollbar::-webkit-scrollbar-thumb {
    @apply bg-success-900 rounded-full;
}


.sidebar-scrollbar::-webkit-scrollbar {
    @apply w-1 h-1.5;
}

.sidebar-scrollbar::-webkit-scrollbar-track {
    @apply w-1 bg-success-850 rounded-full;
}

.sidebar-scrollbar::-webkit-scrollbar-thumb {
    @apply bg-primary1 rounded-full;
}

.left-scrollbar{
    direction: rtl;
}


.common-scrollbar::-webkit-scrollbar {
    @apply w-1 h-1.5;
}

.common-scrollbar::-webkit-scrollbar-track {
    @apply w-1 bg-light_gray;
}

.common-scrollbar::-webkit-scrollbar-thumb {
    @apply bg-dark_gray rounded-full;
}

button,
a {
    @apply outline-0;
}

.specialities-table tr td:first-child {
    @apply rounded-tl-md rounded-bl-md border-y border-s border-success-100;
}

.specialities-table tr td {
    @apply rounded-tl-md rounded-bl-md border-y border-success-100;
}

.specialities-table tr td:last-child {
    @apply rounded-tr-md rounded-br-md border-y border-e border-success-100;
}

// .table-striped .ant-table-content table thead tr th:first-child{
//     @apply rounded-tl-none
// }
.table-striped .ant-table-content table tbody tr:nth-child(odd) {
    @apply bg-success-250;
}
.table-striped .ant-table-content table tbody tr:hover {
    @apply bg-success-250 ;
}

.table-striped .ant-table-content table thead tr th:first-child,
.table-striped .ant-table-content table tbody tr td:first-child{
    @apply text-[15px] font-medium;
    // border-left: 1px solid #D1E9E7;
}
.table-striped .ant-table-content table tbody tr:hover td{
    background: #E3FFFD !important;
}
.table-striped .ant-table-content table tbody tr td,
.table-striped .ant-table-content table thead tr th {
    @apply border-b-0 p-1 border-e-borderColor ;
}