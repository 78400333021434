@font-face {
    font-family: 'Urbanist';
    src: url('Urbanist-Black.woff2') format('woff2'),
        url('Urbanist-Black.woff') format('woff'),
        url('Urbanist-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Urbanist';
    src: url('Urbanist-Regular.woff2') format('woff2'),
        url('Urbanist-Regular.woff') format('woff'),
        url('Urbanist-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Urbanist';
    src: url('Urbanist-SemiBold.woff2') format('woff2'),
        url('Urbanist-SemiBold.woff') format('woff'),
        url('Urbanist-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Urbanist';
    src: url('Urbanist-ExtraBold.woff2') format('woff2'),
        url('Urbanist-ExtraBold.woff') format('woff'),
        url('Urbanist-ExtraBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Urbanist';
    src: url('Urbanist-Medium.woff2') format('woff2'),
        url('Urbanist-Medium.woff') format('woff'),
        url('Urbanist-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Urbanist';
    src: url('Urbanist-Light.woff2') format('woff2'),
        url('Urbanist-Light.woff') format('woff'),
        url('Urbanist-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Urbanist';
    src: url('Urbanist-Bold.woff2') format('woff2'),
        url('Urbanist-Bold.woff') format('woff'),
        url('Urbanist-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

