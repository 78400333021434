.container {
    @apply mx-auto
}

.meetime-card {
    @apply bg-white border-2 border-success-100 rounded-[15px] shadow-100 py-[18px] px-4;
}

.auth-bg-image {
    @apply bg-cover bg-center;
    background-image: url('../../../public/assets/images/auth/auth-bg.webp');
}
.ant-form-item{
    margin-bottom: 0 !important;
}
.availability-form-item .ant-form-item-explain{
    @apply absolute top-0
}
.availability-timepicker .ant-picker-clear{
    @apply end-[calc(100%-8px)] bg-transparent
}
.slider-with-custom-navigation.slick-slider .slick-arrow::before{
   @apply hidden

}

.slider-with-custom-navigation.slick-slider .slick-arrow::before {
    @apply hidden
}

.slider-with-custom-navigation.slick-slider .slick-arrow {
    display: flex !important;
}

.slider-with-custom-navigation.slick-slider .slick-arrow.slick-disabled {
    display: none !important;
}

.slider-with-custom-navigation.slick-slider .slick-prev:hover,
.slider-with-custom-navigation.slick-slider .slick-prev:focus,
.slider-with-custom-navigation.slick-slider .slick-next:hover,
.slider-with-custom-navigation.slick-slider .slick-next:focus {
    @apply bg-primary1
}

.fancy_shaped_heading {
    position: relative;
}

.fancy_shaped_heading::before {
    content: "";
    background-image: url('../../../public/assets/images/homepage/Vector.png');
    width: 160px;
    height: 100px;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    position: absolute;
    bottom: -20px;
    left: -18px;
}

.colored_dots_wrapper .dot {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    display: block;
    flex-shrink: 0;
}

.colored_dots_wrapper .sky {
    background-color: #3bdffd;
}

.colored_dots_wrapper .blue {
    background-color: #4fb7ff;
}

.colored_dots_wrapper .green {
    background-color: #5fffd2;
}

@media screen and (max-width: 1200px) {
    .colored_dots_wrapper .dot {
        @apply w-3 h-3
    }

    .fancy_shaped_heading::before {
        width: 140px;
        height: 90px;
    }
}

@media screen and (max-width: 800px) {
    .colored_dots_wrapper .dot {
        @apply w-2.5 h-2.5
    }

    .fancy_shaped_heading::before {
        width: 120px;
        height: 80px;
    }
}